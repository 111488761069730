<template>
  <div > 
     <governmentBondDetail/>
  </div>
</template>

<script>
import governmentBondDetail from "@/components/GovernmentBondDetail.vue";
export default {
  name: "GovernmentBondDetail",
  components: {
    governmentBondDetail
  }
};
</script>