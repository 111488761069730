<template>
  <div class="home">

    <dashboard />
  </div>
</template>

<script>
// @ is an alias to /src
import dashboard from "@/components/InfraPXDashboard.vue";

export default {
  name: "InfraPXDashboard",
  components: {
    dashboard
  }
};
</script>
