<template>
  <KTSearchResult v-bind:data="result"></KTSearchResult>
</template>

<script>
import KTSearchResult from "@/components/Header/SearchResult.vue";
export default {
  name: "KTSearchDefault",
  components: { KTSearchResult },
  data() {
    return {
      data: this.result,
      loading: false,
      // dummy search result data
      result: [
        {
          text: "Notifications",
          type: 0,
        },
        {
          icon: "flaticon2-box text-danger",
          text: "2 New items submitted",
          desc: "Marketing Manager",
          type: 1,
        },
        {
          icon: "flaticon2-psd text-brand",
          text: "79 PSD files generated",
          desc: "by Grog John",
          type: 1,
        },
        {
          icon: "flaticon2-supermarket text-warning",
          text: "$2900 worth products sold",
          desc: "Total 234 items",
          type: 1,
        },
        {
          icon: "flaticon2-shield text-info",
          text: "4 New items submitted",
          desc: "Marketing Manager",
          type: 1,
        },
      ],
    };
  },
  methods: {
    /**
     * Check if the data has result
     * @returns {boolean}
     */
    hasResult() {
      return this.data.length || false;
    },
    /**
     * Reset search data
     */
    reset() {
      this.data = [];
    },
  },
};
</script>
