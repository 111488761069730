<template>
  <b-row style="width: 100%; flex-wrap: nowrap">
    <b-col cols="12">
      <div style="display: flex; justify-content: space-evenly">
        <div class="card" style="width: 100%; margin-left: 40px">
          <div class="row d-flex justify-content-center">
            <chart
              ref="chart"
              :title="chartTitle"
              :style="{
                width: '90%',
                height: '400px',
                font: '18px Verdana',
              }"
              :legend-position="'top'"
              :series="series"
              :category-axis="categoryAxis"
              :value-axis="valueAxis"
              :theme="'sass'"
              :autobind="false"
              :tooltip-visible="true"
              :tooltip-format="'{0}'"
            >
            </chart>
          </div>
          <div class="row justify-content-center">
            <span
              style="
                padding-top: 15px;
                padding-right: 15px;
                padding-bottom: 15px;
                padding-left: 15px;
              "
            >
              <label
                for="classSelector"
                style="
                  padding-top: 5px;
                  padding-right: 5px;
                  padding-bottom: 5px;
                  padding-left: 5px;
                "
                ><b>Asset Class </b>
              </label>
              <combobox
                :data-items="inputDataClass"
                :text-field="'name'"
                :value="classValue"
                @change="onChangeClass"
                id="classSelector"
              ></combobox>
            </span>
            <span
              style="
                padding-top: 15px;
                padding-right: 15px;
                padding-bottom: 15px;
                padding-left: 15px;
              "
            >
              <label
                for="municipalitySelector"
                style="
                  padding-top: 5px;
                  padding-right: 5px;
                  padding-bottom: 5px;
                  padding-left: 5px;
                "
                ><b>Municipality </b></label
              >
              <combobox
                :data-items="inputDataMunicipality"
                :text-field="'name'"
                :value="municipalityValue"
                @change="onChangeMunicipality"
                id="municipalitySelector"
              ></combobox>
            </span>
          </div>
          <b-tabs card>
            <b-tab title="Table" active>
              <p>
                <b> <h5>Bonds Issued Per Year</h5></b>
              </p>
              <Grid :data-items="result" :columns="columns" :resizable="true">
                <template v-slot:headerTemplate="{ props }">
                  <b>{{ props.field }}</b>
                </template>
              </Grid>
            </b-tab>
            <b-tab title="Map" lazy>
              <p>
                <b> <h4>Project Locations</h4></b>
              </p>
              <kendo-map
                :key="mapkey"
                ref="map"
                :center="markers[0] ? markers[0].latlng : []"
                :zoom="10"
                style="width: 100%; height: 500px"
              >
                <kendo-map-layer
                  :type="'tile'"
                  :url-template="'https://#= subdomain #.tile.openstreetmap.org/#= zoom #/#= x #/#= y #.png'"
                  :subdomains="['a', 'b', 'c']"
                  :attribution="'&copy; <a href=\'https://osm.org/copyright\'>OpenStreetMap contributors</a>'"
                >
                </kendo-map-layer>
                <kendo-map-layer
                  :type="'marker'"
                  :data-source="markers"
                  :location-field="'latlng'"
                  :title-field="'name'"
                ></kendo-map-layer>
              </kendo-map>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { Chart } from "@progress/kendo-charts-vue-wrapper";
import { Grid } from "@progress/kendo-vue-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Map, MapLayer, MapMarker } from "@progress/kendo-map-vue-wrapper";
import { ComboBox } from "@progress/kendo-vue-dropdowns";

import {
  MultiColumnComboBox,
  MultiColumnComboBoxColumn,
} from "@progress/kendo-dropdowns-vue-wrapper";
export default {
  name: "HomeDashboardChart",
  components: {
    chart: Chart,
    Grid: Grid,
    "kendo-map": Map,
    "kendo-map-layer": MapLayer,
    combobox: ComboBox,
  },
  computed: {
    inputData() {
      return this.$store.state.inputData;
    },

    bondData() {
      return this.$store.state.bondData;
    },
    inputDataClass() {
      return this.inputData.filter((data) => data.type == "class");
    },

    inputDataMunicipality() {
      return this.inputData.filter((data) => data.type == "municipality");
    },
  },
  data: function () {
    return {
      municipalityValue: "",
      classValue: "",
      markers: [],
      mapkey: "1",
      chartTitle: {
        text: "Market Activity",
        font: "18px Verdana",
        align: "center",
      },
      result: [],
      yearArray: [],
      performanceArray: [],
      bondsArray: [],
      bondCountArray: [],
      columns: [{ field: "Asset" }],
      series: [],
      valueAxis: [
        {
          title: { text: "Performance", font: "18px Verdana" },
          min: 0,
          max: 1,
          majorUnit: 0.1,
          labels: {
            font: "18px Verdana",
          },
        },
        {
          name: "bond",
          color: "#4e4141",
          labels: {
            format: "{0:c0}",
            font: "18px Verdana",
          },
        },
      ],
      categoryAxis: {},
    };
  },
  methods: {
    //Load the CSV file to seed data for the table and the graph
    readFile() {
      var endYear = this.$config.startYear + this.$config.numberOfYears,
        years = [];
      var startYear = this.$config.startYear || 1980;
      while (startYear < endYear) {
        years.push(startYear++);
      }
      this.yearArray = years;
      this.categoryAxis = {
        categories: this.yearArray,

        axisCrossingValues: [100, 0],
        labels: {
          font: "11px Verdana",
        },
      };
      this.columns = this.yearArray.map((value, idx) => ({
        field: value + "",
        width: "120px",
        headerCell: "headerTemplate",
      }));
      this.columns.unshift({
        field: "Asset",
        width: "100px",
        locked: "true",
        headerCell: "headerTemplate",
      });
    },

    redrawChartClass(newValue) {
      var selectedRow = this.inputData.find((obj) => {
        return obj.id == newValue.id;
      });

      this.markers = [];
      var bondData = this.$store.state.bondData;
      if (selectedRow.type == "class") {
        if (selectedRow.name.trim() !== "All") {
          bondData = bondData.filter(
            (bond) => bond.asset_class.trim() == selectedRow.name.trim()
          );
        }
      } else {
        if (selectedRow.name.trim() !== "All") {
          bondData = bondData.filter(
            (bond) => bond.owner.trim() == selectedRow.name.trim()
          );
        }
      }
      for (var j = 0; j < bondData.length; j++) {
        this.markers.push({
          latlng: [bondData[j].longitude, bondData[j].latitude],
          name:
            "<a href=#/detail/" +
            bondData[j].bond_id +
            ">" +
            bondData[j].asset_id +
            "</a>",
        });
      }
      this.mapkey = selectedRow.name;

      this.performanceArray = Object.values(
        Object.keys(selectedRow)
          .filter((key) => key.includes("performance"))
          .reduce((obj, key) => {
            obj[key] = selectedRow[key];
            return obj;
          }, {})
      );

      this.bondsArray = Object.values(
        Object.keys(selectedRow)
          .filter((key) => key.includes("amount"))
          .reduce((obj, key) => {
            obj[key] = selectedRow[key];
            return obj;
          }, {})
      );

      this.bondCountArray = Object.values(
        Object.keys(selectedRow)
          .filter((key) => key.includes("bond"))
          .reduce((obj, key) => {
            obj[key] = selectedRow[key];
            return obj;
          }, {})
      );

      this.series = [
        {
          type: "line",
          data: this.performanceArray,
          stack: true,
          name: "Performance",
          color: "#ce8c0d",
          width: 4,
        },

        {
          type: "column",
          data: this.bondsArray,
          name: "Total Bonds Issued",
          color: "#6ea0b0",
          axis: "bond",
        },
      ];

      this.result = [];
      var bondCounts = {};
      var bondAmount = {};
      bondCounts["Asset"] = "# Bonds";
      bondAmount["Asset"] = "Total Issued";
      for (var i = 0; i < this.yearArray.length; i++) {
        bondCounts[this.yearArray[i]] = this.bondCountArray[i];
        bondAmount[this.yearArray[i]] = this.formatNumber(this.bondsArray[i]);
      }
      this.result.push(bondCounts);
      this.result.push(bondAmount);
      this.chartTitle = {
        text:
          "Market Activity" +
          " (" +
          selectedRow.type +
          ":" +
          selectedRow.name +
          ")",
        font: "18px Verdana",
        align: "center",
      };
    },
    onChangeClass(event) {
      this.municipalityValue = "";
      this.classValue = event.target.value;
      this.redrawChartClass(this.classValue);
    },
    onChangeMunicipality(event) {
      this.classValue = "";
      this.municipalityValue = event.target.value;
      this.redrawChartClass(this.municipalityValue);
    },
  },

  watch: {
    inputData: function (val) {
      this.readFile();
      this.classValue = this.inputDataClass[0];
      this.redrawChartClass(this.classValue);
    },
  },

  created: function () {
    this.readFile();
    this.classValue = this.inputDataClass[0];
    this.redrawChartClass(this.classValue);
  },
};
</script>
