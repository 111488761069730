<template>
  <div class="dashboard">
    <h1 class="header-color" style="text-align: left; padding: 10px">
      <font-awesome-icon
        size="1x"
        class="header-color"
        :icon="['fas', 'hand-holding-usd']"
      />
      Financials
    </h1>
    <div class="government">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <b-card class="dashboard-card">
              <div class="row d-flex justify-content-center">
                <chart
                  ref="chart"
                  :title="chartTitle"
                  :style="{
                    width: '100%',
                    height: '400px',
                    font: '16px Verdana',
                  }"
                  :legend-position="'top'"
                  :series="series"
                  :category-axis="categoryAxis"
                  :value-axis="valueAxis"
                  :theme="'sass'"
                  :autobind="false"
                  :tooltip-visible="true"
                  :tooltip-format="'{0}'"
                  :series-defaults-type="'column'"
                >
                </chart>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card class="dashboard-card">
              <div class="container-fluid">
                 <p>
                  <b> <h4>InfraPX Bonds</h4></b>
                </p>
                <div class="row flex-row flex-nowrap">
                  <Grid
                    ref="financial"
                    :style="{ height: '900px', width: '100%' }"
                    :data-items="gridData"
                    :selected-field="selectedField"
                    :columns="staticColumns"
                    @rowclick="onRowClick"
                    :sortable="sortable"
                    :filterable="filterable"
                    :groupable="groupable"
                    :reorderable="reorderable"
                    :pageable="{ buttonCount: 4, pageSizes: true }"
                    :skip="skip"
                    :take="take"
                    :sort="sort"
                    :group="group"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    @expandchange="expandChange"
                  >
                  </Grid>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { Map, MapLayer, MapMarker } from "@progress/kendo-map-vue-wrapper";
import { Grid } from "@progress/kendo-vue-grid";
import { process } from "@progress/kendo-data-query";
import { Chart, ChartSeriesItem } from "@progress/kendo-charts-vue-wrapper";
export default {
  name: "continueIssuance",
  components: {
    Grid: Grid,
    chart: Chart,
  },
  props: ["type"],
  computed: {
    items() {
      let financialData = this.$store.state.financialData;
      let merged = [];
      var currentYear = new Date().getFullYear();

      for (let i = 0; i < financialData.length; i++) {
        var bondID = financialData[i]["bond_id"];
        var projectName = this.$store.state.bondData.filter(
          (item) => item.bond_id == bondID
        )[0].descriptor;
        var assetClass = this.$store.state.bondData.filter(
          (item) => item.bond_id == bondID
        )[0].asset_class;
        var investor = this.$store.state.bondData.filter(
          (item) => item.bond_id == bondID
        )[0].investor;

        merged.push(
          Object.keys(financialData[i])
            .map((key) => {
              if (key !== "bond_id") {
                if (
                  Number(key) > currentYear &&
                  financialData[i][key] !== null
                ) {
                  return {
                    bond_id: bondID,
                    date: key,
                    amount: null,
                    amount_pending: financialData[i][key],
                    status: "Pending",
                    descriptor: projectName,
                    asset_class: assetClass,
                    investor: investor,
                  };
                } else if (
                  Number(key) <= currentYear &&
                  financialData[i][key] !== null
                ) {
                  return {
                    bond_id: bondID,
                    date: key,
                    amount: financialData[i][key],
                    amount_pending: null,
                    status: "Paid",
                    descriptor: projectName,
                    asset_class: assetClass,
                    investor: investor,
                  };
                }
              }
            })
            .filter((item) => item)
        );
      }
      if (this.type == "investor") {
        return merged.flat().filter((item) => item.investor == "Investor 1");
      } else {
        return merged.flat();
      }
    },
    selectedItem() {
      if (this.items)
        return this.items.find((item) => item.bond_id === this.selectedID);
      else {
        return null;
      }
    },
  },
  data: function () {
    return {
      chartTitle: {
        text: "Yield Payments",
        font: "18px Verdana",
        align: "center",
      },
      gridData: [],
      sortable: true,
      filterable: true,
      groupable: true,
      reorderable: true,
      expandedItems: [],
      skip: 0,
      take: 20,
      sort: [{ field: "date", dir: "desc" }],
      group: [{ field: "date" }],
      filter: null,
      selectedField: "selected",
      selectedID: 1,
      staticColumns: [
        { field: "bond_id", title: "Bond ID", width: "300px" },
        { field: "asset_class", title: "Asset Class", width: "400px" },
        { field: "descriptor", title: "Project Name", width: "400px" },
        { field: "date", title: "Date", width: "300px" },
        {
          field: "amount",
          title: "Amount Paid",
          filter: "numeric",
          cell: this.amountCellFunction,
          width: "300px",
        },
        {
          field: "amount_pending",
          title: "Amount Pending",
          filter: "numeric",
          cell: this.amountPendingCellFunction,
          width: "300px",
        },
        {
          field: "status",
          title: "Payment Status",
          filter: "text",
          width: "300px",
        },
      ],
      valueAxis: [
        {
          line: {
            visible: false,
          },
          minorGridLines: {
            visible: true,
          },
          labels: {
            rotation: "auto",
            format: "{0:c0}",
          },
        },
      ],
      categoryAxis: {
        categories: [],
        majorGridLines: {
          visible: false,
        },
      },
      tooltip: {
        visible: true,
        template: "#= 'Amount' #: #= value #",
      },
      series: [
        { name: "Amount_Paid", data: [] },
        { name: "Amount_Pending", data: [] },
      ],
    };
  },
  created: function () {
    const dataState = {
      skip: this.skip,
      take: this.take,
      sort: this.sort,
      group: this.group,
    };
    this.gridData = process(this.items, dataState);

    let uniqueClasses = [
      ...new Set(this.items.map((item) => item.asset_class)),
    ];
    var returnData = [];
    uniqueClasses.forEach((item) =>
      returnData.push({
        category: item,
        value: this.items.filter((element) => element.asset_class == item)
          .length,
      })
    );

    //chart
    var financialDataDateArray = Object.keys(
      this.$store.state.financialData.map(({ bond_id, ...item }) => item)[0]
    );

    this.categoryAxis = {
      categories: financialDataDateArray,
      majorGridLines: {
        visible: false,
      },
    };

    this.series = [];
    var amountPaid = [];
    financialDataDateArray.forEach((element) =>
      amountPaid.push(
        this.items
          .filter((item) => item.date == element)
          .map((a) => a.amount)
          .reduce((a, b) => Math.round(a + b), 0)
      )
    );
    this.series.push({ name: "Paid", data: amountPaid ,color: "#6ea0b0"});

    var amountPending = [];
    financialDataDateArray.forEach((element) =>
      amountPending.push(
        this.items
          .filter((item) => item.date == element)
          .map((a) => a.amount_pending)
          .reduce((a, b) => Math.round(a + b), 0)
      )
    );
    this.series.push({ name: "Future", data: amountPending, color: "#ce8c0d" });
  },
  methods: {
    amountCellFunction: function (h, tdElement, props, listeners) {
      return h("td", {}, [this.formatNumber(props.dataItem.amount)]);
    },
    amountPendingCellFunction: function (h, tdElement, props, listeners) {
      return h("td", {}, [this.formatNumber(props.dataItem.amount_pending)]);
    },
    onRowClick(event) {
      this.selectedID = event.dataItem.bond_id;
      if (this.selectedID !== undefined)
        this.$router.push({ name: "detail", params: { id: this.selectedID } });
    },
    getData: function () {
      let dataState = {
        take: this.take,
        skip: this.skip,
        filter: this.filter,
        sort: this.sort,
      };

      this.gridData = process(this.items, dataState);
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      if (dataState.group) {
        dataState.group.map((group) => (group.aggregates = this.aggregates));
      }
      this.group = dataState.group;
      this.filter = dataState.filter;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
      this.createAppState(event.data);

      //Change  chart data when the data is filtered
      var allFilteredData = process(this.items, {
        skip: 0,
        take: this.items.length,
        filter: this.filter,
      })
        .data.flat()
        .filter((item) => item);

      var financialDataDateArray = Object.keys(
        this.$store.state.financialData.map(({ bond_id, ...item }) => item)[0]
      );

      this.series = [];
      var amountPaid = [];
      financialDataDateArray.forEach((element) =>
        amountPaid.push(
          allFilteredData
            .filter((item) => item.date == element)
            .map((a) => a.amount)
            .reduce((a, b) => Math.round(a + b), 0)
        )
      );
      this.series.push({ name: "Paid", data: amountPaid ,color: "#6ea0b0"});

      var amountPending = [];
      financialDataDateArray.forEach((element) =>
        amountPending.push(
          allFilteredData
            .filter((item) => item.date == element)
            .map((a) => a.amount_pending)
            .reduce((a, b) => Math.round(a + b), 0)
        )
      );
      this.series.push({ name: "Future", data: amountPending, color: "#ce8c0d" });

      //

      this.gridData = process(this.items, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter,
        group: this.group,
      });
    },
    expandChange: function (event) {
      event.dataItem[event.target.$props.expandField] = event.value;
    },
    pageChangeHandler: function (event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    columnReorder: function (options) {
      this.columns = options.columns;
    },
  },
};
</script>


