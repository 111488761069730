import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@progress/kendo-theme-bootstrap/dist/all.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import '@progress/kendo-ui'
import { KendoButtonsInstaller } from '@progress/kendo-buttons-vue-wrapper'
import { KendoDataSourceInstaller } from '@progress/kendo-datasource-vue-wrapper'
import { KendoGridInstaller } from '@progress/kendo-grid-vue-wrapper'
import { Chart,
  ChartSeriesItem,
  Sparkline,
  SparklineSeriesItem,
  StockChart,
  ChartInstaller } from '@progress/kendo-charts-vue-wrapper'
import VuePapaParse from 'vue-papa-parse'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
Vue.use(VueSidebarMenu)
import PerfectScrollbar from "vue2-perfect-scrollbar";

// Perfect scrollbar
Vue.use(PerfectScrollbar);

window.PerfectScrollbar = PerfectScrollbar;

library.add(fas)
library.add(fab)
library.add(far)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false;
Vue.use(KendoButtonsInstaller)
Vue.use(VuePapaParse)
Vue.use(KendoGridInstaller)
Vue.use(KendoDataSourceInstaller)
Vue.use(ChartInstaller)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
})],
  state: {
    inputData: [],
    bondData: [],
    fundedPerformanceData:[],
    unfundedPerformanceData:[],
    historicalPerformanceData:[],
    financialData:[]
  },
  mutations: {
    storeFile(state, file) {
      state.inputData = file;
    },
    storeBondFile(state, file) {
      state.bondData = file;
    },
    storeFundedPerformanceData(state, file) {
      state.fundedPerformanceData = file;
    },
    storeUnfundedPerformanceData(state, file) {
      state.unfundedPerformanceData = file;
    },
    storeHistoricalPerformanceData(state, file) {
      state.historicalPerformanceData = file;
    },
    storeFinancialData(state, file) {
      state.financialData = file;
    },
  }

})

Vue.mixin({
  methods: {
    formatNumber: function (
      amount,
      decimalCount = 0,
      decimal = ".",
      thousands = ","
    ) {
      try {
        if (!isNaN(amount)) {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "$";

        let i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : "")
        );
          }
          else
          {
            return "";
          }
      } catch (e) {
        console.log(e);
      }
    },
    formatYield: function (number) {
      if (!isNaN(number)) {
        return Math.round( (number * 100) * 100 + Number.EPSILON ) / 100  + "%";
      } else {
        return "";
      }
    },
  }
})

fetch(process.env.BASE_URL + "config.json")
  .then(response => response.json())
  .then(config => {
    Vue.prototype.$config = config
    new Vue({
      router,
      components: {
        Chart,
        Sparkline,
        StockChart
    },
      store: store,
      render: (h) => h(App)
    }).$mount("#app")
  });


