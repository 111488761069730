<template>
  <b-navbar type="dark" sticky z-index="1" class="main-navbar">
    <!-- Right aligned nav items -->
    <router-link to="/home" v-show="!showHeader"
      ><img class="logo" src="@/assets/logo.png" />
    </router-link>

    <b-navbar-nav class="ml-auto">
      <b-dropdown
        size="sm"
        id="kt_quick_search_toggle"
        variant="link"
        toggle-class="topbar-item text-decoration-none"
        no-caret
        right
        no-flip
        style="width: 60px; padding-right: 15px"
      >
        <template v-slot:button-content>
          <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
            <span
              class="svg-icon svg-icon-xl svg-icon-primary"
              style="font-size: larger; color: white"
            >
              <font-awesome-icon :color="'white'" :icon="['fas', 'search']" />
            </span>
          </div>
        </template>
        <b-dropdown-text tag="div" style="width: 350px">
          <SearchDefault></SearchDefault>
        </b-dropdown-text>
      </b-dropdown>

      <b-dropdown
        size="sm"
        id="kt_quick_search_toggle"
        variant="link"
        toggle-class="topbar-item text-decoration-none"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
            <span
              class="svg-icon svg-icon-xl svg-icon-primary"
              style="font-size: larger; color: white"
            >
              <font-awesome-icon :color="'white'" :icon="['fas', 'bell']" />
            </span>
          </div>
        </template>
        <b-dropdown-text tag="div" style="width: 350px">
          <Notifications></Notifications>
        </b-dropdown-text>
      </b-dropdown>

      <!--begin: Language bar -->
      <div class="topbar-item">
        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
          no-caret
          right
          no-flip
          style="padding-top: 7px"
        >
          <template v-slot:button-content>
            <img class="flag" :src="languageFlag || getLanguageFlag" alt="" />
          </template>
          <b-dropdown-text tag="div" class="min-w-md-175px">
            <KTDropdownLanguage
              v-on:language-changed="onLanguageChanged"
            ></KTDropdownLanguage>
          </b-dropdown-text>
        </b-dropdown>
      </div>
      <!--end: Language bar -->

      <b-nav-item-dropdown right style="padding-top: 15px">
        <!-- Using 'button-content' slot -->
        <template #button-content>
          <b
            style="
              font-size: large;
              color: white;
              padding-right: 15px;
              padding-left: 15px;
            "
            >Hi, Waterloo</b
          >
          <b-badge
            pill
            style="font-size: large; color: white; background-color: #cb8b0f"
            >W</b-badge
          >
        </template>
        <b-dropdown-item href="#">Profile</b-dropdown-item>
        <b-dropdown-item href="#">Sign Out</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import SearchDefault from "@/components/Header/SearchDefault.vue";
import Notifications from "@/components/Header/Notifications.vue";
import KTDropdownLanguage from "@/components/Header/DropdownLanguage.vue";
import i18nService from "@/core/services/i18n.service.js";
export default {
  components: {
    SearchDefault,
    KTDropdownLanguage,
    Notifications,
  },
  props: ["showHeader"],
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
    };
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
  },
};
</script>
<style scoped>
.flag {
  width: 30px;
  margin-top: 5px;
  padding-top: 5px;
  border-radius: 40%;
}
.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.btn:active {
  outline: 0 !important;
  box-shadow: none !important;
}
</style>