<template>
  <div class="home">
    <h2>
      <p class="text-center">
        Normally it's very difficult for a municipality to issue a bond and an
        investor to purchase them. With InfraPX we make that process 60% easier.
        Bond's are directly linked to municipal projects which is how we can
        track their performance over time
      </p>
    </h2>
    <div >
      <DashboardChart> </DashboardChart>
    </div>
  </div>
</template>

<script>
import DashboardChart from "@/components/HomeDashboardChart.vue";
export default {
  name: "HomeDashboard",
  components: {
    DashboardChart,
  },
  
};
</script>
