<template>
  <div class="government">
    <h1 class="header-color" style="text-align: left; padding: 10px">

      Bond Detail
    </h1>
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <b-card class="dashboard-card">
            <div class="container-fluid">
                          <p style="font:18.288px -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;white-space:pre;" >Bond Detail</p>

              <div class="row flex-row flex-nowrap">
                <Grid
                  :data-items="inputData"
                  :columns="staticColumns"
                  :resizable="true"
                  :style="{ width: '100%' }"
                  :scrollable="'none'"
                >
                  <template v-slot:headerTemplate="{ props }">
                    <b>{{ props.field }}</b>
                  </template>
                </Grid>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row align-h="between">
        <b-col cols="6">
          <b-card class="dashboard-card">
            <chart
              ref="unFundedChart"
              :style="{ height: '150px', width: '100%' }"
              :title-text="'Future Performance without Funding'"
              :legend-visible="false"
              :series-defaults-type="'line'"
              :series="seriesUnfunded"
              :category-axis="categoryAxis"
              :value-axis="valueAxis"
              :tooltip="tooltip"
              :value-axis-axis-major-grid-lines-visible="true"
              :value-axis-axis-visible="false"
              :category-axis-major-grid-lines-visible="false"
              :category-axis-major-ticks-visible="false"
              :theme="'sass'"
            >
            </chart> </b-card
        ></b-col>
        <b-col cols="6">
          <b-card class="dashboard-card">
            <chart
              ref="fundedChart"
              :style="{ height: '150px', width: '100%' }"
              :title-text="'Future Performance with Funding'"
              :legend-visible="false"
              :series-defaults-type="'line'"
              :series="seriesFunded"
              :category-axis="categoryAxis"
              :value-axis="valueAxis"
              :tooltip="tooltip"
              :value-axis-axis-major-grid-lines-visible="true"
              :value-axis-axis-visible="false"
              :category-axis-major-grid-lines-visible="false"
              :category-axis-major-ticks-visible="false"
              :theme="'sass'"
            >
            </chart> </b-card
        ></b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-card class="dashboard-card">
            <chart
              ref="historicalChart"
              :style="{ height: '300px', width: '100%' }"
              :title-text="'Historical Performance'"
              :legend-visible="false"
              :series-defaults-type="'line'"
              :series="seriesHistorical"
              :category-axis="historicalCategoryAxis"
              :value-axis="valueAxis"
              :tooltip="tooltip"
              :value-axis-axis-major-grid-lines-visible="true"
              :value-axis-axis-visible="false"
              :category-axis-major-grid-lines-visible="false"
              :category-axis-major-ticks-visible="false"
              :theme="'sass'"
            >
            </chart> </b-card
        ></b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card class="dashboard-card">
            <p style="font:18.288px -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;white-space:pre;" >Project Location</p>
            <kendo-map
              ref="map"
              :center="markers[0] ? markers[0].latlng : []"
              :zoom="15"
            >
              <kendo-map-layer
                :type="'tile'"
                :url-template="'https://#= subdomain #.tile.openstreetmap.org/#= zoom #/#= x #/#= y #.png'"
                :subdomains="['a', 'b', 'c']"
                :attribution="'&copy; <a href=\'https://osm.org/copyright\'>OpenStreetMap contributors</a>'"
              >
              </kendo-map-layer>
              <kendo-map-layer
                :type="'marker'"
                :data-source="markers"
                :location-field="'latlng'"
                :title-field="'name'"
              ></kendo-map-layer>
            </kendo-map>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Grid } from "@progress/kendo-vue-grid";
import { Chart, ChartSeriesItem } from "@progress/kendo-charts-vue-wrapper";
import { Map, MapLayer, MapMarker } from "@progress/kendo-map-vue-wrapper";
export default {
  name: "GovernmentBondDetail",
  components: {
    Grid: Grid,
    chart: Chart,
    "kendo-map": Map,
    "kendo-map-layer": MapLayer,
  },
  computed: {
    bondId() {
      return this.$route.params.id;
    },
    inputData() {
      return this.$store.state.bondData.filter(
        (item) => item.bond_id == this.bondId
      );
    },
    fundedPerformanceData() {
      let objectArray = this.$store.state.fundedPerformanceData.filter(
        (item) => item.bond_id == this.bondId
      );
      return Object.values(
        Object.keys(objectArray[0])
          .filter((key) => key.includes("funded"))
          .reduce((obj, key) => {
            obj[key] = objectArray[0][key];
            return obj;
          }, {})
      );
    },
    unfundedPerformanceData() {
      let objectArray = this.$store.state.unfundedPerformanceData.filter(
        (item) => item.bond_id == this.bondId
      );
      return Object.values(
        Object.keys(objectArray[0])
          .filter((key) => key.includes("funded"))
          .reduce((obj, key) => {
            obj[key] = objectArray[0][key];
            return obj;
          }, {})
      );
    },
    historicalPerformanceData() {
      let objectArray = this.$store.state.historicalPerformanceData
        .filter((item) => item.bond_id == this.bondId)
        .map(({ bond_id, ...item }) => item);

      return Object.values(
        Object.keys(objectArray[0]).reduce((obj, key) => {
          obj[key] = objectArray[0][key];
          return obj;
        }, {})
      );
    },
  },
  data: function () {
    return {
      seriesFunded: [],
      seriesUnfunded: [],
      seriesHistorical: [],
      markers: [],
      valueAxis: [
        {
          max: 1,
          min: -1,
          line: {
            visible: false,
          },
          minorGridLines: {
            visible: true,
          },
          labels: {
            rotation: "auto",
          },
        },
      ],
      categoryAxis: {},
      historicalCategoryAxis: {},
      tooltip: {
        visible: true,
        template: "#= 'performance' #: #= value #",
      },
      staticColumns: [
        { field: "owner", title: "Municipality", width: "200px" },
        { field: "asset_id", title: "Asset ID", width: "200px" },
        { field: "asset_class", title: "Asset Class", width: "200px" },
        { field: "descriptor", title: "Project", width: "200px" },
        {
          field: "amount",
          title: "Amount (CAD)",
          format: "${0}",
          filter: "numeric",
          width: "150px",
          cell: this.amountCellFunction,
        },
        {
          field: "yield",
          title: "Yield",
          filter: "numeric",
          format: "{0}%",
          width: "100px",
          cell: this.yieldCellFunction,
        },
        {
          field: "term",
          title: "Term (month)",
          filter: "numeric",
          width: "200px",
        },
        {
          field: "maturity",
          title: "Maturity",
          filter: "date",
          width: "200px",
        },
        { field: "date_added", title: "Added", filter: "date", width: "300px" },
        { field: "status", title: "Status", width: "200px" },
      ],
    };
  },

  created: function () {
    this.seriesFunded.push({
      data: this.fundedPerformanceData,
      style: "smooth",
        color:"#6ea0b0",
      markers: {
        visible: false,
      },
    });

    this.seriesUnfunded.push({
      data: this.unfundedPerformanceData,
      style: "smooth",
        color:"#6ea0b0",
      markers: {
        visible: false,
      },
    });

    var axisValues = [];
    for (var i = 0; i < this.fundedPerformanceData.length; i++) {
      axisValues.push(i);
    }

    this.categoryAxis = {
      categories: axisValues,
      majorGridLines: {
        visible: false,
      },
    };

    this.seriesHistorical.push({
      data: this.historicalPerformanceData,
      style: "smooth",
        color:"#6ea0b0",
      markers: {
        visible: false,
      },
    });

    var historicalPerformanceHeaders = Object.keys(
      this.$store.state.historicalPerformanceData
        .filter((item) => item.bond_id == this.bondId)
        .map(({ bond_id, ...item }) => item)[0]
    );
    this.historicalCategoryAxis = {
      categories: historicalPerformanceHeaders,
      majorGridLines: {
        visible: false,
      },
    };

    this.markers.push({
      latlng: [this.inputData[0].longitude, this.inputData[0].latitude],
    });
  },
  methods: {
    yieldCellFunction: function (h, tdElement, props, listeners) {
      return h("td", {}, [this.formatYield(props.dataItem.yield)]);
    },
    amountCellFunction: function (h, tdElement, props, listeners) {
      return h("td", {}, [this.formatNumber(props.dataItem.amount)]);
    },
  },
};
</script>