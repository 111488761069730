import Vue from "vue";
import VueRouter from "vue-router";
import GovernmentDashboard from "../views/GovernmentDashboard.vue";
import HomeDashboard from "../views/HomeDashboard.vue";
import GovernmentBondDetail from "../views/GovernmentBondDetail.vue";
import InfraPXDashboard from "../views/InfraPXDashboard.vue";
import Financial from "../views/Financials.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/home'
  },

  {
    path: "/home",
    name: "home",
    component: HomeDashboard
  },
  {
    path: "/infrapxdashboard",
    name: "infrapxdashboard",
    component: InfraPXDashboard
  },
  {
    path: "/government",
    name: "dashboard",
    component: GovernmentDashboard,

  },
  {
    name: "detail",
    path: '/detail/:id',
    component: GovernmentBondDetail
  },
  {
    path: "/investor",
    name: "Investor",
    component: () =>
      import( "../views/InvestorsDashboard.vue")
  },
  {
    path: "/issuance/:id?",
    name: "Issuance",
    component: () =>
      import( "../views/Issuance.vue")
  },
  {
    path: "/continueIssuance",
    name: "ContinueIssuance",
    component: () =>
      import( "../views/ContinueIssuance.vue")
  },
  {
    path: "/issueBond",
    name: "IssueBond",
    component: () =>
      import( "../views/IssueBond.vue")
  },
  {
    path: "/financials",
    name: "Financials",
    component: Financial,
      props: { type: "government" }
  },
  {
    path: "/investorFinancials",
    name: "InvestorFinancials",
    component: Financial,
    props: { type: "investor" }
  },
  
  {
    path: "/purchaseBonds",
    name: "PurchaseBonds",
    component: () =>
      import( "../views/PurchaseBonds.vue")
  },
];

const router = new VueRouter({
  routes,
  base: process.env.BASE_URL + "/home",
  linkActiveClass: 'active'
});

export default router;
