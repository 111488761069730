<template>
  <div class="home">

    <dashboard  :key="type" v-bind:type="type"/>
  </div>
</template>

<script>
import dashboard from "@/components/Financials.vue";

export default {
  name: "Financials",
  components: {
    dashboard
  },
   props: ["type"],
};
</script>
