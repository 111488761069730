<template>
  <div class="dashboard">
    <h1 class="header-color" style="text-align: left; padding: 10px">
      <font-awesome-icon
        size="1x"
        class="header-color"
        :icon="['fas', 'university']"
      />
      Government
    </h1>

    <b-container fluid>
      <b-row style="width: 100%; flex-wrap: nowrap">
        <b-col cols="1">
          <div class="tile-icon">
            <span class="comp-label">
              <font-awesome-icon
                size="4x"
                color="grey"
                :icon="['fas', 'dollar-sign']"
              />
            </span>
          </div>
        </b-col>
        <b-col>
          <div class="tile">
            <span class="comp-label">
              <small>Listed Bonds Total Value</small>
              <span>
                <strong
                  ><b>{{ totalAmount }}</b></strong
                ></span
              >
            </span>
          </div>
        </b-col>
        <b-col>
          <div class="tile">
            <span class="comp-label">
              <small>Funded Bonds Total Value</small>
              <span>
                <strong
                  ><b>{{ totalInvested }}</b></strong
                ></span
              >
            </span>
          </div>
        </b-col>
        <b-col>
          <div class="tile">
            <span class="comp-label">
              <small>Total Yield Paid (2010 - Present)</small>
              <span>
                <strong
                  ><b>{{ totalYieldAmount }}</b></strong
                ></span
              >
            </span>
          </div>
        </b-col>
        <b-col>
          <div class="tile">
            <span class="comp-label">
              <small>Current Year Yield Paid</small>
              <span>
                <strong
                  ><b>{{ annualYieldSpend }}</b></strong
                ></span
              >
            </span>
          </div>
        </b-col>
      </b-row>

      <b-row style="width: 100%; flex-wrap: nowrap">
        <b-col cols="1">
          <div class="tile-icon">
            <span class="comp-label">
              <font-awesome-icon
                size="4x"
                color="grey"
                :icon="['fas', 'money-check-alt']"
              />
            </span>
          </div>
        </b-col>
        <b-col>
          <div class="small-tile">
            <span class="comp-label">
              <small>Bonds Listed</small>
              <span>
                <strong
                  ><b>{{ numberOfBonds }}</b></strong
                ></span
              >
            </span>
          </div>
        </b-col>
        <b-col>
          <div class="small-tile">
            <span class="comp-label">
              <small>Funded Bonds</small>
              <span>
                <strong
                  ><b>{{ numberOfBondsFunded }}</b></strong
                ></span
              >
            </span>
          </div>
        </b-col>
        <b-col>
          <div class="small-tile">
            <span class="comp-label">
              <small>Planned or Presale Bonds</small>
              <span>
                <strong
                  ><b>{{ numberOfBondsPlanned }}</b></strong
                ></span
              >
            </span>
          </div>
        </b-col>

        <b-col>
          <div class="small-tile">
            <span class="comp-label">
              <small>Active Bonds</small>
              <span>
                <strong
                  ><b>{{ numberOfBondsActive }}</b></strong
                ></span
              >
            </span>
          </div>
        </b-col>

        <b-col cols="1">
          <div class="tile-icon">
            <span class="comp-label">
              <font-awesome-icon
                size="4x"
                color="grey"
                :icon="['fas', 'city']"
              />
            </span>
          </div>
        </b-col>

        <b-col>
          <div class="small-tile">
            <span class="comp-label">
              <small>Municipalities</small>
              <span>
                <strong
                  ><b>{{ numberOfMunicipalities }}</b></strong
                ></span
              >
            </span>
          </div>
        </b-col>

        <b-col>
          <div class="small-tile">
            <span class="comp-label">
              <small>Investors</small>
              <span>
                <strong
                  ><b>{{ numberOfInvestors }}</b></strong
                ></span
              >
            </span>
          </div>
        </b-col>
      </b-row>

      <b-row style="width: 100%; flex-wrap: nowrap">
        <b-col cols="12">
          <div style="display: flex; justify-content: space-evenly">
            <div class="card" style="width: 70%; margin-left: 40px">
              <chart
                ref="chart"
                :title-text="'Yield - Size'"
                :legend-position="'bottom'"
                :series-defaults-type="'scatter'"
                :series="scatterSeries"
                :x-axis-title-text="'Yield'"
                :x-axis-crosshair-visible="true"
                :x-axis-crosshair-tooltip-visible="true"
                :x-axis-crosshair-tooltip-format="'n2'"
                :y-axis-title-text="'Size'"
                :y-axis-crosshair-visible="true"
                :y-axis-crosshair-tooltip-visible="true"
                :y-axis-crosshair-tooltip-format="'n0'"
                :y-axis="yAxis"
                :x-axis-labels-format="'{0}%'"
                :theme="'sass'"
              >
              </chart>
            </div>
            <div class="card" style="width: 30%">
              <chart
                :title-text="'Asset Classes'"
                :chart-area-background="''"
                :legend-position="'bottom'"
                :legend="legend"
                :chart-area="plotArea"
                :series="series"
                :series-defaults-labels-visible="false"
                :series-defaults-labels-position="'outsideEnd'"
                :series-defaults-labels-background="'transparent'"
                :series-defaults-labels-template="labelTemplate"
                :tooltip-visible="true"
                :tooltip-template="tooltipTemplate"
                :theme="'sass'"
              >
              </chart>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row style="width: 100%; flex-wrap: nowrap">
        <b-col cols="12">
          <div style="display: flex; justify-content: space-evenly">
            <div class="card" style="width: 100%; margin-left: 40px">
               <p>
                  <b> <h4>InfraPX Bonds</h4></b>
                </p>
              <Grid
                ref="governmentDashboard"
                :style="{ height: '700px', width: '100%' }"
                :data-items="gridData"
                :selected-field="selectedField"
                :columns="staticColumns"
                @rowclick="onRowClick"
                :sortable="sortable"
                :filterable="filterable"
                :groupable="groupable"
                :reorderable="reorderable"
                :pageable="{ buttonCount: 4, pageSizes: true }"
                :skip="skip"
                :take="take"
                :sort="sort"
                :group="group"
                :filter="filter"
                @datastatechange="dataStateChange"
              >
              </Grid>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { Chart } from "@progress/kendo-charts-vue-wrapper";
import { Map, MapLayer, MapMarker } from "@progress/kendo-map-vue-wrapper";
import { Grid } from "@progress/kendo-vue-grid";
import { process } from "@progress/kendo-data-query";
export default {
  name: "governmentDashboard",
  components: {
    chart: Chart,
    Grid: Grid,
  },
  computed: {
    financialItems() {
      let financialData = this.$store.state.financialData;
      var currentYear = new Date().getFullYear();
      let merged = [];
      for (let i = 0; i < financialData.length; i++) {
        var bondID = financialData[i]["bond_id"];
        var projectName = this.$store.state.bondData.filter(
          (item) => item.bond_id == bondID
        )[0].descriptor;
        var assetClass = this.$store.state.bondData.filter(
          (item) => item.bond_id == bondID
        )[0].asset_class;
        var owner = this.$store.state.bondData.filter(
          (item) => item.bond_id == bondID
        )[0].owner;
        merged.push(
          Object.keys(financialData[i])
            .map((key) => {
              if (key !== "bond_id") {
                if (
                  Number(key) > currentYear &&
                  financialData[i][key] !== null
                ) {
                  return {
                    bond_id: bondID,
                    date: key,
                    amount: null,
                    amount_pending: financialData[i][key],
                    status: "Pending",
                    descriptor: projectName,
                    asset_class: assetClass,
                    owner: owner,
                  };
                } else if (
                  Number(key) <= currentYear &&
                  financialData[i][key] !== null
                ) {
                  return {
                    bond_id: bondID,
                    date: key,
                    amount: financialData[i][key],
                    amount_pending: null,
                    status: "Paid",
                    descriptor: projectName,
                    asset_class: assetClass,
                    owner: owner,
                  };
                }
              }
            })
            .filter((item) => item)
        );
      }

      return merged.flat().filter((item) => item.owner == "Waterloo");
    },

    items() {
      return this.$store.state.bondData.filter(
        (item) => item.owner == "Waterloo"
      );
    },
    selectedItem() {
      if (this.items)
        return this.items.find((item) => item.bond_id === this.selectedID);
      else {
        return null;
      }
    },
    totalAmount() {
      var formatter = new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: "CAD",
      });
      if (this.allFilteredData.length > 0) {
        return formatter
          .format(
            Math.round(
              this.allFilteredData.reduce((a, b) => a + (b["amount"] || 0), 0)
            )
          )
          .replace(/(\.|,)00$/g, "");
      } else {
        return 0;
      }
    },
    totalInvested() {
      var formatter = new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: "CAD",
      });
      if (this.allFilteredData.length > 0) {
        return formatter
          .format(
            Math.round(
              this.allFilteredData
                .filter(
                  (data) => data.status == "Closed" || data.status == "Active"
                )
                .reduce((a, b) => a + (b["amount"] || 0), 0)
            )
          )
          .replace(/(\.|,)00$/g, "");
      } else {
        return 0;
      }
    },

    numberOfBonds() {
      return this.allFilteredData.length;
    },
    numberOfBondsFunded() {
      return this.allFilteredData.filter(
        (data) => data.status == "Closed" || data.status == "Active"
      ).length;
    },

    numberOfBondsPlanned() {
      return this.allFilteredData.filter(
        (data) => data.status == "Planned" || data.status == "Pending"
      ).length;
    },

    numberOfMunicipalities() {
      let uniqueMunicipalities = [
        ...new Set(this.allFilteredData.map((item) => item.owner)),
      ];
      return uniqueMunicipalities.length;
    },

    numberOfInvestors() {
      let uniqueInvestors = [
        ...new Set(this.allFilteredData.map((item) => item.investor)),
      ];
      uniqueInvestors = Array.from(uniqueInvestors).filter(
        (item) => item !== null
      );
      return uniqueInvestors.length;
    },

    numberOfProjectsCompleted() {
      return this.allFilteredData.filter((data) => data.status == "Closed")
        .length;
    },
    numberOfBondsActive() {
      return this.allFilteredData.filter((data) => data.status == "Active")
        .length;
    },
  },
  data: function () {
    return {
      yAxis: {
        name: "Size",
        color: "#4e4141",
        labels: {
          format: "{0:c0}",
        },
      },
      plotArea: { height: 450 },
      legend: {
        labels: {
          margin: 5,
        },
      },
      scatterSeries: [],
      annualYieldSpend: 0,
      totalYieldAmount: 0,
      chartData: {},
      allFilteredData: this.$store.state.bondData.filter(
        (item) => item.owner == "Waterloo"
      ),
      filteredFinancialItems: [],
      gridData: [],
      sortable: true,
      filterable: true,
      groupable: true,
      reorderable: true,
      skip: 0,
      take: 20,
      sort: [{ field: "owner", dir: "desc" }],
      group: [{ field: "owner" }],
      filter: null,
      selectedField: "selected",
      selectedID: 1,
      staticColumns: [
        { field: "owner", title: "Municipality", width: "250px" },
        { field: "asset_id", title: "Asset ID", width: "250px" },
        { field: "asset_class", title: "Asset Class", width: "250px" },
        { field: "descriptor", title: "Project", width: "250px" },
        {
          field: "amount",
          title: "Amount (CAD)",
          format: "${0}",
          filter: "numeric",
          width: "250px",
          cell: this.amountCellFunction,
        },
        {
          field: "yield",
          title: "Yield",
          filter: "numeric",
          format: "{0}%",
          width: "250px",
          cell: this.yieldCellFunction,
        },
        {
          field: "term",
          title: "Term (month)",
          filter: "numeric",
          width: "200px",
        },
        {
          field: "maturity",
          title: "Maturity",
          filter: "numeric",
          width: "200px",
        },
        {
          field: "date_added",
          title: "Added",
          filter: "numeric",
          width: "200px",
        },
        { field: "status", title: "Status", width: "200px" },
      ],
      series: [],

      labelTemplate: "#= category # - #= kendo.format('{0:P}', percentage)#",
      tooltipTemplate: "#= category # - #= kendo.format('{0:P}', percentage) #",
    };
  },
  created: function () {
    const dataState = {
      skip: this.skip,
      take: this.take,
      sort: this.sort,
      group: this.group,
    };
    this.gridData = process(this.items, dataState);

    let uniqueClasses = [
      ...new Set(this.items.map((item) => item.asset_class)),
    ];
    var dataItems = [];
    uniqueClasses.forEach((item) =>
      dataItems.push({
        category: item,
        value: this.items.filter((element) => element.asset_class == item)
          .length,
      })
    );
    this.chartData = { type: "pie", data: dataItems };
    this.series.push(this.chartData);

    //calculating yield
    this.filteredFinancialItems = this.financialItems;
    if (this.filteredFinancialItems !== undefined) {
      var currentYear = new Date().getFullYear();
      var financialDataDateArray = Object.keys(
        this.$store.state.financialData.map(({ bond_id, ...item }) => item)[0]
      );

      var amountPaid = 0;
      var currentYearItem = financialDataDateArray.find(
        (item) => item == currentYear
      );
      if (currentYearItem !== undefined) {
        amountPaid = this.filteredFinancialItems
          .filter((item) => item.date == currentYearItem)
          .map((a) => a.amount)
          .reduce((a, b) => Math.round(a + b), 0);

        var totalAmountPaid = this.filteredFinancialItems
          .filter((item) => item.date <= currentYearItem)
          .map((a) => a.amount)
          .reduce((a, b) => Math.round(a + b), 0);
      }

      this.annualYieldSpend = this.formatNumber(amountPaid);
      this.totalYieldAmount = this.formatNumber(totalAmountPaid);
    } else {
      this.annualYieldSpend = 0;
      this.totalYieldAmount = 0;
    }
    //

    // scattered chart data
    this.scatterSeries = [];
    let uniqueTerms = Array.from([
      ...new Set(this.allFilteredData.map((item) => item.term)),
    ]).sort((a, b) => a - b);

    uniqueTerms.forEach((item) => {
      var data = this.allFilteredData
        .filter((element) => element.term == item)
        .map((bond) => [bond.yield * 100, Math.round(bond.amount)]);

      this.scatterSeries.push({
        name: "Term: " + item,
        data: data,
      });
    });

    //
  },
  methods: {
    yieldCellFunction: function (h, tdElement, props, listeners) {
      return h("td", {}, [this.formatYield(props.dataItem.yield)]);
    },
    amountCellFunction: function (h, tdElement, props, listeners) {
      return h("td", {}, [this.formatNumber(props.dataItem.amount)]);
    },
    onRowClick(event) {
      this.selectedID = event.dataItem.bond_id;
      if (this.selectedID !== undefined) {
        this.$router.push({ name: "detail", params: { id: this.selectedID } });
      } else {
        this.$emit("rowCLick");
      }
    },
    getData: function () {
      let dataState = {
        take: this.take,
        skip: this.skip,
        filter: this.filter,
        sort: this.sort,
      };

      this.gridData = process(this.items, dataState);
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      this.group = dataState.group;
      this.filter = dataState.filter;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
      this.createAppState(event.data);

      //Change card and chart data when the data is filtered
      this.allFilteredData = process(this.items, {
        skip: 0,
        take: this.items.length,
        filter: this.filter,
      })
        .data.flat()
        .filter((item) => item);

      //calculating yield
      this.filteredFinancialItems = this.financialItems.filter(
        (item) =>
          this.allFilteredData
            .map((object) => object.bond_id)
            .indexOf(item.bond_id) > -1
      );
      if (this.filteredFinancialItems !== undefined) {
        var currentYear = new Date().getFullYear();
        var financialDataDateArray = Object.keys(
          this.$store.state.financialData.map(({ bond_id, ...item }) => item)[0]
        );

        var amountPaid = 0;
        var currentYearItem = financialDataDateArray.find(
          (item) => item == currentYear
        );
        if (currentYearItem !== undefined) {
          amountPaid = this.filteredFinancialItems
            .filter((item) => item.date == currentYearItem)
            .map((a) => a.amount)
            .reduce((a, b) => Math.round(a + b), 0);
          var totalAmountPaid = this.filteredFinancialItems
            .filter((item) => item.date <= currentYearItem)
            .map((a) => a.amount)
            .reduce((a, b) => Math.round(a + b), 0);
        }

        this.annualYieldSpend = this.formatNumber(amountPaid);
        this.totalYieldAmount = this.formatNumber(totalAmountPaid);
      } else {
        this.annualYieldSpend = 0;
        this.totalYieldAmount = 0;
      }
      //
      let uniqueClasses = [
        ...new Set(this.allFilteredData.map((item) => item.asset_class)),
      ];

      var dataItems = [];
      this.series = [];
      uniqueClasses.forEach((item) =>
        dataItems.push({
          category: item,
          value: this.allFilteredData.filter(
            (element) => element.asset_class == item
          ).length,
        })
      );
      this.chartData = { type: "pie", data: dataItems };
      this.series.push(this.chartData);

      //

      // scattered chart data
      this.scatterSeries = [];
      let uniqueTerms = Array.from([
        ...new Set(this.allFilteredData.map((item) => item.term)),
      ]).sort((a, b) => a - b);

      uniqueTerms.forEach((item) => {
        var data = this.allFilteredData
          .filter((element) => element.term == item)
          .map((bond) => [bond.yield * 100, Math.round(bond.amount)]);

        this.scatterSeries.push({
          name: "Term: " + item,
          data: data,
        });
      });

      //

      this.gridData = process(this.items, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter,
        group: this.group,
      });
    },
    expandChange: function (event) {
      event.dataItem[event.target.$props.expandField] = event.value;
    },
    pageChangeHandler: function (event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    columnReorder: function (options) {
      this.columns = options.columns;
    },
  },
};
</script>


