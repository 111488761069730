<template>
  <div>
    <div id="app" class="app">
      <sidebar-menu
        class="main-sidebar"
        :collapsed="collapsed"
        :menu="menu"
        :width="'325px'"
        :showOneChild="true"
        :relative="true"
        :hideToggle="true"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick"
         :disableHover="true"
      >
        <span slot="toggle-icon">
          <font-awesome-icon size="1x" :icon="['fas', 'arrows-alt-h']" />
        </span>
        <span slot="dropdown-icon">
          <font-awesome-icon size="1x" :icon="['fas', 'caret-right']"
        /></span>
        <span slot="header" style="background-color: #2e3235; height: 53px">
          <router-link to="/home" v-show="showHeader"
            ><img class="logo" src="./assets/logo.png" />
          </router-link>
          <!-- <button class="toggle-button vsm--orange">
            <font-awesome-icon
              v-show="showHeader"
              @click="collapse(collapsed)"
              size="2x"
              :icon="['fas', 'angle-double-left']"
            />
            <font-awesome-icon
              v-show="!showHeader"
              @click="collapse(collapsed)"
              size="2x"
              :icon="['fas', 'angle-double-right']"
            /></button -->
        ></span>
      </sidebar-menu>

      <main>
        <Header v-bind:showHeader="showHeader" />
        <div style="margin-right: 10px">
          <router-view :key="routerKey"></router-view>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import inputDataCSV from "./assets/csv_files/homeDashboardInputData.csv";
import bondDataCSV from "./assets/csv_files/bondData.csv";
import fundedPerformanceDataCSV from "./assets/csv_files/fundedPerformanceData.csv";
import unfundedPerformanceDataCSV from "./assets/csv_files/unfundedPerformanceData.csv";
import historicalPerformanceDataCSV from "./assets/csv_files/historicalPerformanceData.csv";
import financialDataCSV from "./assets/csv_files/financials_government.csv";
export default {
  name: "main-menu",
  components: {
    Header,
  },
  data() {
    return {
      collapsed: false,
      showHeader: true,
      routerKey: 0,
      transformValue: "translateX(0)",
      navState: true,
      menu: [
        {

          type: "toggle",
          class: "vsm--orange",
          icon: {
            element: "font-awesome-icon",
            attributes: { icon: ["fas", "angle-double-left"] },
          },
        },
        {
          href: "/infrapxdashboard",
          title: "InfraPX - Dashboard",
          icon: {
            element: "font-awesome-icon",
            attributes: { icon: ["fa", "chart-area"] },
          },
        },
        {
          title: "Government",
          icon: {
            element: "font-awesome-icon",
            attributes: { icon: ["fas", "university"] },
          },
          child: [
            {
              href: "/government",
              title: "Dashboard",
              class: "vsm--child",
              icon: {
                element: "font-awesome-icon",
                attributes: { icon: ["fas", "chart-line"] },
              },
            },
            {
              title: "Bond",
              class: "vsm--orange vsm--child--inner",
              child: [
                {
                  href: "/issuance",
                  title: "Start Issuance Process",
                  class: "vsm--child--inner",
                  icon: {
                    element: "font-awesome-icon",
                    attributes: { icon: ["fas", "plus"] },
                  },
                },
                {
                  href: "/continueIssuance",
                  title: "Continue Issuance Process",
                  class: "vsm--child--inner",
                  icon: {
                    element: "font-awesome-icon",
                    attributes: { icon: ["fas", "tasks"] },
                  },
                },
                {
                  href: "/issueBond",
                  title: "Issue Bond to Market",
                  class: "vsm--child--inner",
                  icon: {
                    element: "font-awesome-icon",
                    attributes: { icon: ["fas", "award"] },
                  },
                },
              ],
            },
            {
              title: "Portfolio",
              class: "vsm--orange vsm--child--inner",
              child: [
                {
                  href: "/financials",
                  title: "Financials",
                  class: "vsm--child--inner",
                  icon: {
                    element: "font-awesome-icon",
                    attributes: { icon: ["fas", "hand-holding-usd"] },
                  },
                },
              ],
            },
          ],
        },
        {
          title: "Investor",
          icon: {
            element: "font-awesome-icon",
            attributes: { icon: ["fas", "dollar-sign"] },
          },
          child: [
            {
              href: "/investor",
              title: "Dashboard",
              class: "vsm--child",
              icon: {
                element: "font-awesome-icon",
                attributes: { icon: ["fas", "chart-line"] },
              },
            },
            {
              title: "Bond",
              class: "vsm--orange vsm--child--inner",

              child: [
                {
                  href: "/purchaseBonds",
                  title: "Purchase Bonds",
                  class: "vsm--child--inner",
                  icon: {
                    element: "font-awesome-icon",
                    attributes: { icon: ["fas", "search-dollar"] },
                  },
                },
              ],
            },
            {
              title: "Portfolio",
              class: "vsm--orange vsm--child--inner",
              child: [
                {
                  href: "/investorFinancials",
                  title: "Financials",
                  class: "vsm--child--inner",
                  icon: {
                    element: "font-awesome-icon",
                    attributes: { icon: ["fas", "hand-holding-usd"] },
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  },

  mounted: function () {
    this.$store.commit("storeFile", inputDataCSV);
    this.$store.commit("storeBondFile", bondDataCSV);
    this.$store.commit("storeFundedPerformanceData", fundedPerformanceDataCSV);
    this.$store.commit(
      "storeUnfundedPerformanceData",
      unfundedPerformanceDataCSV
    );
    this.$store.commit(
      "storeHistoricalPerformanceData",
      historicalPerformanceDataCSV
    );
    this.$store.commit("storeFinancialData", financialDataCSV);
    if (window.innerWidth > 1600) {
      this.collapsed = false;
      this.showHeader = true;
    } else {
      this.collapsed = true;
      this.showHeader = false;
    }
  },

  methods: {
    onToggleCollapse(collapsed) {
      this.showHeader = !this.showHeader;
      this.collapsed = !collapsed;
      setTimeout(() => {
        this.routerKey = collapsed + "";
      }, 400);
    },
    collapse(collapsed) {
      this.onToggleCollapse(collapsed);
    },
    onItemClick(event, item, node) {
      if (item.type == "toggle") {
        if (!this.collapsed) {
          item.icon = {
            element: "font-awesome-icon",
            attributes: { icon: ["fas", "angle-double-right"] },
          };
        } else {
          item.icon = {
            element: "font-awesome-icon",
            attributes: { icon: ["fas", "angle-double-left"] },
          };
        }
         this.onToggleCollapse(this.collapsed);
      }
    },
  },
};
</script>


<style lang="scss">
@import "./shared/custom";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";
@import "./shared/_navi";
@import "./node_modules/bootstrap/scss/bootstrap";
@import "./components/dashboard";
.container {
  margin: 40px;
}
html,
body {
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}
body {
  margin: 0;
}
span.active {
  background-color: gray;
}
.col-xs {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-xs-12 {
  flex: 0 0 100%;
}
.nav-toggle .k-i-hamburger {
  font-size: 32px;
}
.v-sidebar-menu .vsm--orange {
  color: $primary-color-orange !important;
}

.v-sidebar-menu .vsm--child {
  padding-left: 20px !important;
}

.v-sidebar-menu .vsm--child--inner {
  padding-left: 40px !important;
}

.v-sidebar-menu .vsm--link.vsm--link_exact-active {
  color: #1ca8dd;
}
.toggle-button {
  float: right;
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 0px;
  padding-top: 5px;
}

.toggle-button:focus {
  outline: -webkit-focus-ring-color auto 0px !important;
}
.logo {
  height: 35px;
  margin-left: 4px;
  margin-top: 4px;
}
.v-sidebar-menu .vsm--icon {
  background-color: inherit !important;
}
.main-sidebar {
  z-index: 2000 !important;
  height: 100vh !important;
  background-color: $secondary-color !important;
  position: sticky !important;
}
.main-navbar {
  background-color: $primary-color-grey !important;
  height: 50px !important;
}
</style>
